<template>
  <div>
    <div class="title"><h3>Dados do pacote</h3></div>
    <aRow :gutter="20">
      <aCol :span="24">
        <a-form-item class="mt-0 f12">
          <label class="label mb-10"> Locais de visitação </label>
          <a-transfer
            class="data-selector"
            :dataSource="formatDataToAntdTransfer(destinationsList)"
            :titles="['Destinos', 'Selecionado']"
            :render="(item) => item.name"
            :filter-option="
              (inputValue, item) => item.filterName.indexOf(inputValue) !== -1
            "
            :target-keys="selectedDestinations"
            :list-style="{
              width: '47%',
              height: '250px',
            }"
            show-search
            @change="onSelectDestination"
          >
            <template slot="children"> </template>
          </a-transfer>
        </a-form-item>
      </aCol>

      <aCol :span="24"> <a-divider class="mt-0" /> </aCol>

      <aCol :span="9">
        <a-form-item class="mb-0">
          <label class="label"> Datas do pacote </label>
          <a-radio-group
            class="relative"
            v-decorator="['package_schedules']"
            size="small"
            style="top: -8px"
          >
            <a-radio
              class="f12"
              v-for="(schedule, i) in packageSchedules"
              :key="i"
              :value="schedule.value"
            >
              {{ schedule.label }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </aCol>

      <aCol
        v-if="tempProduct.package_schedules === 'Mínimo de noites/dias'"
        :span="6"
      >
        <aFormItem class="travel-input-outer">
          <label class="filled"> Mínimo de noites </label>
          <aInput
            class="travel-input"
            placeholder="Insira"
            type="nmber"
            v-decorator="[
              `package_min_nights`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="24"></aCol>

      <aCol
        class="mb-20"
        v-if="tempProduct.package_schedules === 'Range de Data'"
        :span="12"
      >
        <div class="multi-select-calendar">
          <label class="label"> Datas de ida </label>
          <mDatePicker
            v-model="departureDates"
            :disp="ptBrCalendar"
            :multi="true"
            :format="formatMultiDate"
          ></mDatePicker>
        </div>
      </aCol>

      <aCol
        class="mb-20"
        v-if="tempProduct.package_schedules === 'Range de Data'"
        :span="12"
      >
        <div class="multi-select-calendar">
          <label class="label">Datas de volta </label>
          <mDatePicker
            v-model="returnDates"
            :disp="ptBrCalendar"
            :multi="true"
            :format="formatMultiDate"
          ></mDatePicker>
        </div>
      </aCol>

      <aCol :span="24"> <a-divider class="mt-0" /> </aCol>

      <aCol :span="8">
        <a-form-item class="mb-0">
          <label class="label"> Saídas </label>
          <a-radio-group
            class="relative"
            v-decorator="['package_departures']"
            size="small"
            style="top: -8px"
          >
            <a-radio
              class="f12"
              v-for="(departure, i) in packageDepartures"
              :key="i"
              :value="departure.value"
            >
              {{ departure.label }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </aCol>

      <aCol
        v-if="tempProduct.package_departures === 'Dias da semana'"
        :span="24"
      >
        <a-form-item>
          <label class="label"> Dias da semana </label>
          <a-checkbox-group
            v-decorator="['package_weekdays']"
            style="width: 100%"
          >
            <a-checkbox
              class="f12"
              v-for="(day, i) in weekDays"
              :key="i"
              :value="day.value"
            >
              {{ day.label }}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-item>
      </aCol>

      <aCol :span="24"> <a-divider class="mt-0" /> </aCol>

      <aCol :span="12">
        <label class="label">Incluso no pacote</label>
        <HayaRepeaterFields
          class="mb-20"
          :form="productForm"
          :arr="tempProduct?.package_includes_rows"
          :fieldsArr="packageIncludesFieldsArr"
          :entry="tempProduct"
          arrName="package_includes_rows"
        />
      </aCol>

      <aCol :span="12">
        <label class="label">Diferenciais do pacote</label>
        <HayaRepeaterFields
          class="mb-20"
          :form="productForm"
          :arr="tempProduct?.package_differentials_rows"
          :entry="tempProduct"
          :fieldsArr="packageDifferentialsFieldsArr"
          arrName="package_differentials_rows"
        />
      </aCol>

      <aCol :span="24"> <a-divider class="mt-0" /> </aCol>

      <aCol :span="24">
        <label class="label"> Observações </label>
        <HayaRichTextField
          module="products"
          subFolder="text-editor"
          :id="tempProduct.id"
          :value="tempProduct.package_observations"
          :placeholder="'Digite o texto aqui'"
          formField="package_observations"
          @textChange="onChangeText"
        />
      </aCol>
    </aRow>
  </div>
</template>

<script>
// components
import HayaRichTextField from "@/components/general/fields/HayaRichTextField.vue";
import HayaRepeaterFields from "@/components/general/fields/HayaRepeaterFields.vue";

// reusables
import productReusables from "../../mixins/productReusables";

export default {
  name: "PackageCommercialDataTab",
  props: {
    productForm: Object,
    tempProduct: Object,
  },
  components: { HayaRichTextField, HayaRepeaterFields },
  mixins: [productReusables],
  data() {
    return {
      departureDates: [],
      returnDates: [],
      selectedDestinations: [],
      destinationsList: [],
      ptBrCalendar: [
        "D",
        "Seg",
        "T",
        "Q",
        "Qu",
        "S",
        "Sa",
        "",
        "",
        "Cancelar",
        "Confirmar",
        "Limpar",
      ],
      weekDays: [
        { label: "Segunda-feira", value: "Segunda-feira" },
        { label: "Terça-feira", value: "Terça-feira" },
        { label: "Quarta-feira", value: "Quarta-feira" },
        { label: "Quinta-feira", value: "Quinta-feira" },
        { label: "Sexta-feira", value: "Sexta-feira" },
        { label: "Sábado", value: "Sábado" },
        { label: "Domingo", value: "Domingo" },
      ],
      packageSchedules: [
        { label: "Range de Data", value: "Range de Data" },
        { label: "Mínimo de noites/dias", value: "Mínimo de noites/dias" },
      ],
      packageDepartures: [
        { label: "Dias da semana", value: "Dias da semana" },
        { label: "Diárias", value: "Diárias" },
      ],
      packageIncludesFieldsArr: [
        {
          type: "text",
          name: "package_includes",
          label: "",
          placeholder: "Escreva...",
          class: "",
          formItemClass: "mb-0",
          required: true,
          colSize: 24,
        },
      ],
      packageDifferentialsFieldsArr: [
        {
          type: "text",
          name: "package_differentials",
          label: "",
          placeholder: "Escreva...",
          class: "",
          formItemClass: "mb-0",
          required: true,
          colSize: 24,
        },
      ],
    };
  },
  mounted() {
    this.getReligions();

    this.departureDates = this.tempProduct?.package_departure_dates
      ? JSON.parse(this.tempProduct.package_departure_dates)
      : [];

    this.returnDates = this.tempProduct?.package_return_dates
      ? JSON.parse(this.tempProduct.package_return_dates)
      : [];

    if (this.departureDates.length > 0) {
      const newArray = this.departureDates.map((date) => {
        return new Date(date);
      });
      this.departureDates = newArray;
    }

    if (this.returnDates.length > 0) {
      const newArray = this.returnDates.map((date) => {
        return new Date(date);
      });
      this.returnDates = newArray;
    }

    setTimeout(() => {
      this.selectedDestinations =
        this.tempProduct["package_destinations"] != undefined
          ? JSON.parse(this.tempProduct["package_destinations"])
          : [];
    }, 500);
  },
  watch: {
    departureDates(newVal) {
      this.tempProduct.package_departure_dates = JSON.stringify(newVal);
    },
    returnDates(newVal) {
      this.tempProduct.package_return_dates = JSON.stringify(newVal);
    },
  },
  methods: {
    onChangeText(field, text) {
      this.tempProduct[field] = text;
    },
    formatMultiDate(date) {
      return date.toLocaleDateString();
    },
    getReligions() {
      this.$http
        .get(`/category-entry/list?page=1&category_id=31&per_page=100`)
        .then(({ data }) => {
          this.destinationsList = data.data;
        });
    },
    onSelectDestination(nextselectedDocs) {
      this.selectedDestinations = nextselectedDocs;
      this.tempProduct["package_destinations"] =
        JSON.stringify(nextselectedDocs);
    },
    formatDataToAntdTransfer(array) {
      const formattedArr = [];

      if (array.length > 0)
        array.forEach((item) => {
          formattedArr.push({
            key: item.name,
            name: `${item.name}`,
            filterName: item.name ? item.name.toLowerCase() : item.name,
            description: "",
          });
        });

      return formattedArr;
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  z-index: 4
  font-size: 10px
  font-weight: 500
  color: #ccc
  line-height: 1
  width: 100%
  margin-bottom: 5px
  display: block
.data-selector
  font-size: 12px
.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  h3
    font-size: 14px
    font-weight: 500
    color: #444
</style>

<style lang="sass">
.multi-select-calendar
  .cal-select
    background-color: #c84779
    color: #FFF
    border-radius: 28px
  .sel-values
    background-color: rgb(200, 71, 121)
    color: #fff
    border-color: rgba(32,160,255,.2)
    font-size: 11px
  .pick-panel
    width: auto
    margin: 0 0 5px 0
    border: 1px solid #eee
    box-shadow: 0 8px 10px #ddd
    border-radius: 6px
    padding: 12px 10px 0
  .btn.btn-cancel
    font-size: 12px
    color: #aaa
    letter-spacing: 0
  .dp-header
    background: #eee
    border-radius: 6px
    padding: 5px
  .btn.btn-ok
    font-size: 12px
    color: #cd4178
    letter-spacing: 0
    font-weight: 600
    margin: 0
  .dp-footer
    width: 100%
    padding: 8px 0
    margin: 8px 0 0 0
    border-top: 1px solid #ebebeb
  .selected-date
    min-width: 100%
    padding: 3px 2px
    border: 1px solid #ddd
    max-width: 100%

.ant-fullcalendar-next-month-btn-day,
.ant-fullcalendar-last-month-cell
  background: #fafafa
  opacity: 0.8

.data-selector
  .ant-checkbox-inner
    z-index: 3
  .ant-transfer-list-search-action
    pointer-events: none
    top: 8px
  .ant-transfer-list-body-with-search
    padding-top: 43px
  .ant-transfer-list-body-search-wrapper
    top: -1px
    padding: 6px
    background: #eee
    .ant-transfer-list-search
      border-radius: 3px
      font-size: 12px

  .ant-transfer-list-content > div
    height: auto
  .ant-transfer-list-content-item
    min-height: 9px
    padding: 3px 8px
    font-size: 11px
    font-weight: 500
</style>
