var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.repeaterRows),function(row,i){return _c('div',{key:i},[_c('aRow',{attrs:{"gutter":10}},[_c('aCol',{attrs:{"span":22}},_vm._l((_vm.fieldsArr),function(field,i){return _c('div',{key:i},[(field.type == 'text')?_c('aCol',{attrs:{"span":field.colSize}},[_c('aFormItem',{staticClass:"travel-input-outer",class:field.formItemClass},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `${field.name}_${row.id}`,
                  {
                    rules: [
                      {
                        required: field.required,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `${field.name}_${row.id}`,\n                  {\n                    rules: [\n                      {\n                        required: field.required,\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",class:field.class,attrs:{"placeholder":field.placeholder}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1):_vm._e()],1)}),0),_vm._v(" "),_c('aCol',{attrs:{"span":2}},[(_vm.repeaterRows.length == row.id)?_c('a-button',{staticClass:"relative top5",attrs:{"type":"danger","shape":"circle","icon":"delete","size":"small"},on:{"click":function($event){return _vm.removeRow(row.id)}}}):_vm._e()],1)],1)],1)}),_vm._v(" "),_c('div',{staticClass:"a-center mt-20"},[_c('a-button',{staticClass:"f12",attrs:{"size":"small"},on:{"click":function($event){return _vm.addRow(_vm.repeaterRows.length)}}},[_vm._v("\n      Adicionar\n    ")]),_vm._v(" "),_c('aFormItem',{staticClass:"none"},[_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([_vm.arrName, { initialValue: _vm.repeaterRowsStr, rules: [] }]),expression:"[arrName, { initialValue: repeaterRowsStr, rules: [] }]"}]},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }