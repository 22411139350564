<template>
  <section>
    <div class="content w-box">
      <aRow :gutter="20">
        <aCol class="tab-icons" :span="2">
          <div v-for="(item, index) in tabs" :key="index">
            <div
              v-if="item.show"
              class="tab grayscale"
              :class="activeProductTab === item.key ? 'active' : ''"
              @click="productActiveTab(item.key)"
            >
              <a-tooltip placement="right">
                <template slot="title">
                  {{ item.name }}
                </template>
                <img :src="item.img" alt="img" width="35" />
                {{ item.alert }}

                <!-- <a-icon
                  v-if="
                    item.key === 'packageData' &&
                    tempProduct.validate_package_fields != 0 &&
                    tempProduct.validate_package_fields != ''
                  "
                  type="warning"
                /> -->
              </a-tooltip>
            </div>
          </div>
        </aCol>

        <aCol class="tab-content" :span="22">
          <div v-if="activeProductTab === 'default'">
            <ProductDefaultDataTab
              :productForm="productForm"
              :tempProduct="tempProduct"
              @onChangeProductType="onChangeProductType"
            />
          </div>

          <div v-if="activeProductTab === 'packageData'">
            <PackageCommercialDataTab
              v-if="tempProduct.type === 'package'"
              :productForm="productForm"
              :tempProduct="tempProduct"
            />
          </div>

          <div v-if="activeProductTab === 'schedule'">
            <PackageScheduleTab
              v-if="tempProduct.type === 'package'"
              :productForm="productForm"
              :tempProduct="tempProduct"
            />
          </div>
        </aCol>
      </aRow>
    </div>
  </section>
</template>

<script>
// components
import ProductDefaultDataTab from "../../tabs/ProductDefaultDataTab.vue";
import PackageCommercialDataTab from "../../tabs/package/PackageCommercialDataTab.vue";
import PackageScheduleTab from "../../tabs/package/PackageScheduleTab.vue";

// images
import edit from "@/assets/images/dashboard/products/edit.png";
import packageImg from "@/assets/images/dashboard/products/package.png";
import schedule from "@/assets/images/dashboard/products/schedule.png";

export default {
  name: "PackageContentSection",
  props: {
    productForm: Object,
    tempProduct: Object,
  },
  components: {
    ProductDefaultDataTab,
    PackageScheduleTab,
    PackageCommercialDataTab,
  },
  data() {
    return {
      activeProductTab: "default",
      tabs: [
        {
          name: "Dados básicos",
          key: "default",
          img: edit,
          show: true,
        },
        {
          name: "Dados do pacote",
          key: "packageData",
          img: packageImg,
          show: false,
        },
        {
          name: "Programação",
          key: "schedule",
          img: schedule,
          show: false,
        },
      ],
    };
  },
  mounted() {
    this.onChangeProductType(this.tempProduct.type);
  },
  methods: {
    onChangeProductType(type) {
      const newTabs = this.tabs.map((tab) => {
        if (tab.key !== "default") tab.show = false;
        if (
          (tab.key === "packageData" || tab.key === "schedule") &&
          type === "package"
        )
          tab.show = true;
        return tab;
      });

      this.tabs = newTabs;
    },
    productActiveTab(active) {
      setTimeout(() => {
        this.activeProductTab = active;
      }, 100);
    },
  },
};
</script>

<style lang="sass" scoped>
.tab-content
  border-left: 1px solid #e0e0e0
  padding-left: 15px !important
  min-height: 640px

.tab-icons
  float: left
  width: 75px
  height: 74vh
  text-align: center
  padding-right: 14px
  .tab
    float: left
    height: 60px
    width: 100%
    line-height: 56px
    margin-bottom: 8px
    cursor: pointer
    border-radius: 6px
    transition: 0.6s
    opacity: 0.3
  .active
    background: #fafafa
    filter: none
    -webkit-filter: grayscale(0)
    -webkit-filter: grayscale(0%)
    opacity: 1
    &:hover
      background: #fafafa
      filter: none
      -webkit-filter: grayscale(0)
      -webkit-filter: grayscale(0%)
      opacity: 1
</style>
