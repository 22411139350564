<template>
  <div>
    <div class="title"><h3>Dados básicos</h3></div>
    <aRow :gutter="20">
      <aCol :span="9">
        <aFormItem class="travel-input-outer">
          <label class="filled"> Nome do produto </label>
          <aInput
            class="travel-input"
            placeholder="Escreva"
            v-decorator="[
              `name`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            @blur="onBlurTitleFormatSlug"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="9">
        <aFormItem class="travel-input-outer">
          <label class="filled"> Slug do produto </label>
          <aInput
            class="travel-input"
            placeholder="Escreva"
            v-decorator="[
              `slug`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            @blur="onBlurSlugFormatSlug"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Tipo de produto </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            v-decorator="[
              `type`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%"
            @change="onChangeProductType"
          >
            <a-select-option
              v-for="(item, index) of productTypes"
              :key="index"
              :value="item.value"
              :txt="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol :span="6">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Status </label>
          <a-select
            class="travel-input"
            :class="$route.name === 'new' ? 'readonly' : ''"
            placeholder="Selecione"
            optionFilterProp="txt"
            v-decorator="[`status`]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of productStatus"
              :key="index"
              :value="item.value"
              :txt="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol :span="2">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Moeda </label>
          <a-select
            class="travel-input w100"
            placeholder="R$"
            optionFilterProp="txt"
            v-decorator="[
              `currency`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <a-select-option
              v-for="(item, index) of currency"
              :key="index"
              :value="item.value"
              :txt="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol :span="4">
        <aFormItem class="travel-input-outer">
          <label class="filled"> Valor </label>
          <aInput
            class="travel-input"
            placeholder="Insira o valor"
            v-currency="{
              currency: {
                prefix: '',
                suffix: '',
              },
            }"
            v-decorator="[
              `value`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Preencha o valor.',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Empresa </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            @change="getCompanyBranchesOnChange"
            v-decorator="[
              `company_id`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
            :disabled="companiesList.length > 0 ? false : true"
          >
            <a-select-option
              v-for="(item, index) of companiesList"
              :key="index"
              :value="item.id"
              :txt="item.trading_name"
            >
              {{ item.id }} - {{ item.trading_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol :span="6">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Filial </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            :disabled="companyBranchesList.length === 0"
            v-decorator="[
              `company_branch_id`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of companyBranchesList"
              :key="index"
              :value="item.id"
              :txt="item.name"
            >
              {{ item.id }} - {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol v-if="$route.name === 'products-budget-edit'" :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Cliente </label>
          <a-select
            class="travel-input"
            placeholder="Selecione ou busque ID ou Nome"
            optionFilterProp="txt"
            :disabled="customers.length === 0"
            :loading="customers.loading"
            @search="onSearchCustomers"
            @select="customers.loading = false"
            v-decorator="[
              `customer_id`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of customers.list"
              :key="index"
              :value="item.id"
              :txt="item.first_name"
            >
              <div v-if="item.person_type === 'Pessoa Física'">
                {{ item.id }} - {{ item.first_name }} {{ item.last_name }}
              </div>
              <div v-if="item.person_type === 'Pessoa Jurídica'">
                {{ item.id }} -{{ item.trading_name }}
              </div>
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>
    </aRow>

    <aRow>
      <aCol :span="24">
        <label class="label"> Descrição </label>
        <HayaRichTextField
          module="products"
          subFolder="text-editor"
          :id="tempProduct.id"
          :value="tempProduct.description"
          :placeholder="'Digite o texto aqui'"
          formField="description"
          @textChange="onChangeText"
        />
      </aCol>
    </aRow>

    <PackageDefaultDataSection
      v-if="tempProduct.type === 'package'"
      class="mt-40"
      :productForm="productForm"
      :tempProduct="tempProduct"
    />
  </div>
</template>

<script>
// libs
import _ from "lodash";

// components
import HayaRichTextField from "@/components/general/fields/HayaRichTextField.vue";
import PackageDefaultDataSection from "../sections/package/PackageDefaultDataSection.vue";

//reuse
import productReusables from "../mixins/productReusables";
import customerMixins from "@/components/customers/mixins/customerMixins";

export default {
  name: "ProductDefaultDataTab",
  props: {
    productForm: Object,
    tempProduct: Object,
  },
  components: { HayaRichTextField, PackageDefaultDataSection },
  mixins: [productReusables, customerMixins],
  data() {
    return {
      companiesList: [],
      companyBranchesList: [],
    };
  },
  mounted() {
    this.setPackageValues();
    if (this.$route.name === "products-budget-edit") {
      this.customers.filters.customerName = this.tempProduct.customer_id;
      this.getCustomers();
    }

    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data;
    });

    if (this.tempProduct["company_id"] != undefined) {
      this.getCompanyBranches(this.tempProduct["company_id"]);
    }
  },
  methods: {
    onSearchCustomers: _.debounce(function (e) {
      if (e.length > 1) {
        this.customers.filters.customerName = e;
        this.getCustomers();
      }
    }, 600),
    slugify(text) {
      let divider = "-";

      // Replace non-letter or digits by divider
      text = text.replace(/[^\p{L}\d]+/gu, divider);

      // Transliterate
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      // Remove unwanted characters
      text = text.replace(/[^-\w]+/g, "");

      // Trim
      text = text.trim(divider);

      // Remove duplicate divider
      text = text.replace(/-+/g, divider);

      // Lowercase
      text = text.toLowerCase();

      if (!text.length) console.log("n-a");

      this.productForm.setFieldsValue({
        slug: text,
      });
    },
    onBlurSlugFormatSlug(text) {
      console.log(text.target.value);
      setTimeout(() => {
        this.slugify(text.target.value);
      }, 100);
    },
    onBlurTitleFormatSlug(text) {
      this.slugify(text.target.value);
    },
    setPackageValues() {
      this.productForm.setFieldsValue(this.tempProduct);
      this.productForm.setFieldsValue({
        status: this.tempProduct.status ? this.tempProduct.status : "draft",
      });

      setTimeout(() => {
        this.productForm.setFieldsValue({
          value: this.tempProduct?.value.replace(".", ","),
          company_id: this.tempProduct.company_id,
        });
      }, 100);
    },
    onChangeText(field, text) {
      this.tempProduct[field] = text;
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&show_in_contract=1&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data;
        })
        .catch(() => {
          this.$message.info("Nenhuma filial encontrada.");
        });
    },
    getCompanyBranchesOnChange(id) {
      this.productForm.setFieldsValue({
        [`company_branch_id`]: undefined,
        company_branch: undefined,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    onChangeProductType(type) {
      this.$emit("onChangeProductType", type);
      setTimeout(() => {
        this.setPackageValues();
      }, 10);

      if (type === "package") this.tempProduct.validate_package_fields = 1;
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  z-index: 4
  font-size: 10px
  font-weight: 500
  color: #ccc
  line-height: 1
  width: 100%
  margin-bottom: 10px
  display: block
.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  h3
    font-size: 14px
    font-weight: 500
    color: #444
</style>
