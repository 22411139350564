var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":10}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Fornecedor ")]),_vm._v(" "),_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `package_supplier`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `package_supplier`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Buscar...","option-label-prop":"value","dropdownMenuStyle":{ width: '300px' },"allowClear":""},on:{"change":_vm.searchProductSupplier}},[_c('template',{slot:"dataSource"},_vm._l((_vm.productSupplier.list),function(product,index){return _c('a-select-option',{key:index,attrs:{"value":product.company_name}},[_vm._v("\n              "+_vm._s(product.id)+" - "+_vm._s(product.company_name)+"\n            ")])}),1),_vm._v(" "),_c('a-input',{staticClass:"travel-input",attrs:{"placeholder":"Origem"}},[_c('a-icon',{attrs:{"slot":"prefix","type":_vm.productSupplier.loading ? 'loading' : 'field-svg'},slot:"prefix"})],1)],2)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Tipo de pacote ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `package_type`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `package_type`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"}},_vm._l((_vm.packageTypes),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"txt":item.label}},[_vm._v("\n            "+_vm._s(item.label)+"\n          ")])}),1)],1)],1),_vm._v(" "),(_vm.tempProduct.package_type === 'Pacote completo com Aéreo')?_c('aCol',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Aeroporto ")]),_vm._v(" "),_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `package_airport`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `package_airport`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Aeroporto","option-label-prop":"value","dropdownMenuStyle":{ width: '300px' },"allowClear":""},on:{"change":_vm.searchAirports}},[_c('template',{slot:"dataSource"},_vm._l((_vm.airportsList),function(airport,index){return _c('a-select-option',{key:index,staticClass:"dropdown-airports",attrs:{"value":airport.name}},[_vm._v("\n              "+_vm._s(airport.name)+"\n              "),_c('a-divider',{attrs:{"type":"vertical"}}),_vm._v("\n              "+_vm._s(airport.meta.airport_name)+"\n            ")],1)}),1),_vm._v(" "),_c('a-input',{staticClass:"travel-input",attrs:{"placeholder":"Origem"}},[_c('a-icon',{attrs:{"slot":"prefix","type":_vm.loadingAirports ? 'loading' : 'field-svg'},slot:"prefix"})],1)],2)],1)],1):_vm._e(),_vm._v(" "),_c('aCol',{attrs:{"span":10}},[_c('a-form-item',[_c('label',{staticClass:"label"},[_vm._v(" Religião ")]),_vm._v(" "),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['package_religion']),expression:"['package_religion']"}]},_vm._l((_vm.religionsList),function(religion,i){return _c('a-radio',{key:i,staticClass:"f12",attrs:{"value":religion.name}},[_vm._v("\n            "+_vm._s(religion.name)+"\n          ")])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }