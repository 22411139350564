<template>
  <div>
    <div class="title"><h3>Programação do pacote</h3></div>
    <PackageScheduleCollapse
      :productForm="productForm"
      :tempProduct="tempProduct"
    />
  </div>
</template>

<script>
// components
import PackageScheduleCollapse from "../../collapses/package/PackageScheduleCollapse.vue";

export default {
  name: "PackageScheduleTab",
  props: {
    productForm: Object,
    tempProduct: Object,
  },
  components: { PackageScheduleCollapse },
};
</script>

<style lang="sass" scoped>
.title
  margin: 0 0 20px 0
  padding: 0 0 0px 0
  border-bottom: 1px solid #f0f0f0
  h3
    font-size: 14px
    font-weight: 500
    color: #444
</style>