<template>
  <div>
    <aRow :gutter="20">
      <aCol :span="10">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Fornecedor </label>
          <a-auto-complete
            placeholder="Buscar..."
            option-label-prop="value"
            :dropdownMenuStyle="{ width: '300px' }"
            v-decorator="[
              `package_supplier`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            allowClear
            @change="searchProductSupplier"
          >
            <template slot="dataSource">
              <a-select-option
                v-for="(product, index) in productSupplier.list"
                :key="index"
                :value="product.company_name"
              >
                {{ product.id }} - {{ product.company_name }}
              </a-select-option>
            </template>
            <a-input class="travel-input" placeholder="Origem">
              <a-icon
                slot="prefix"
                :type="productSupplier.loading ? 'loading' : 'field-svg'"
              />
            </a-input>
          </a-auto-complete>
        </a-form-item>
      </aCol>

      <aCol :span="8">
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Tipo de pacote </label>
          <a-select
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            v-decorator="[
              `package_type`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <a-select-option
              v-for="(item, index) of packageTypes"
              :key="index"
              :value="item.value"
              :txt="item.label"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </aCol>

      <aCol
        v-if="tempProduct.package_type === 'Pacote completo com Aéreo'"
        :span="6"
      >
        <a-form-item class="travel-input-outer">
          <label :class="'filled'"> Aeroporto </label>
          <a-auto-complete
            placeholder="Aeroporto"
            option-label-prop="value"
            :dropdownMenuStyle="{ width: '300px' }"
            v-decorator="[
              `package_airport`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            allowClear
            @change="searchAirports"
          >
            <template slot="dataSource">
              <a-select-option
                class="dropdown-airports"
                v-for="(airport, index) in airportsList"
                :key="index"
                :value="airport.name"
              >
                {{ airport.name }}
                <a-divider type="vertical" />
                {{ airport.meta.airport_name }}
              </a-select-option>
            </template>
            <a-input class="travel-input" placeholder="Origem">
              <a-icon
                slot="prefix"
                :type="loadingAirports ? 'loading' : 'field-svg'"
              />
            </a-input>
          </a-auto-complete>
        </a-form-item>
      </aCol>

      <aCol :span="10">
        <a-form-item>
          <label class="label"> Religião </label>
          <a-radio-group v-decorator="['package_religion']">
            <a-radio
              class="f12"
              v-for="(religion, i) in religionsList"
              :key="i"
              :value="religion.name"
            >
              {{ religion.name }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </aCol>
    </aRow>
  </div>
</template>

<script>
// libs
import _ from "lodash";

// mixins
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  name: "PackageDefaultDataSection",
  props: {
    productForm: Object,
    tempProduct: Object,
  },
  mixins: [productSupplierMixins],
  data() {
    return {
      packageTypes: [
        {
          label: "Pacote Terrestre",
          value: "Pacote Terrestre",
        },
        {
          label: "Pacote completo com Aéreo",
          value: "Pacote completo com Aéreo",
        },
      ],
      loadingAirports: false,
      airportsList: [],
      religionsList: [],
    };
  },
  mounted() {
    this.getReligions();
  },
  methods: {
    searchProductSupplier: _.debounce(function (e) {
      if (e.length > 1) {
        this.productSupplier.filters.searchTerm = e;
        this.getProductSuppliers();
      }
    }, 600),
    getReligions() {
      this.$http
        .get(`/category-entry/list?page=1&category_id=32&per_page=100`)
        .then(({ data }) => {
          this.religionsList = data.data;
        });
    },
    getAirports(airport) {
      this.loadingAirports = true;
      this.$http
        .get(
          `/category-entry/list?page=1&category_id=30&per_page=100&s=${airport}`
        )
        .then(({ data }) => {
          this.airportsList = data.data;
        })
        .finally(() => (this.loadingAirports = false));
    },
    searchAirports: _.debounce(function (e) {
      if (e.length > 1) this.getAirports(e);
    }, 600),
  },
};
</script>

<style lang="sass" scoped>
.label
  z-index: 4
  font-size: 10px
  font-weight: 500
  color: #ccc
  line-height: 1
  width: 100%
  margin-bottom: 5px
  display: block
</style>