var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._v(" "),_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":9}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v(" Nome do produto ")]),_vm._v(" "),_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `name`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `name`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"},on:{"blur":_vm.onBlurTitleFormatSlug}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":9}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v(" Slug do produto ")]),_vm._v(" "),_c('aInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `slug`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `slug`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Escreva"},on:{"blur":_vm.onBlurSlugFormatSlug}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Tipo de produto ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `type`,
            {
              rules: [
                {
                  required: true,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `type`,\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"},on:{"change":_vm.onChangeProductType}},_vm._l((_vm.productTypes),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"txt":item.label}},[_vm._v("\n            "+_vm._s(item.label)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Status ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([`status`]),expression:"[`status`]"}],staticClass:"travel-input",class:_vm.$route.name === 'new' ? 'readonly' : '',staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt"}},_vm._l((_vm.productStatus),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"txt":item.label}},[_vm._v("\n            "+_vm._s(item.label)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":2}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Moeda ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `currency`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `currency`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input w100",attrs:{"placeholder":"R$","optionFilterProp":"txt"}},_vm._l((_vm.currency),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value,"txt":item.label}},[_vm._v("\n            "+_vm._s(item.label)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":4}},[_c('aFormItem',{staticClass:"travel-input-outer"},[_c('label',{staticClass:"filled"},[_vm._v(" Valor ")]),_vm._v(" "),_c('aInput',{directives:[{name:"currency",rawName:"v-currency",value:({
            currency: {
              prefix: '',
              suffix: '',
            },
          }),expression:"{\n            currency: {\n              prefix: '',\n              suffix: '',\n            },\n          }"},{name:"decorator",rawName:"v-decorator",value:([
            `value`,
            {
              rules: [
                {
                  required: false,
                  message: 'Preencha o valor.',
                },
              ],
            },
          ]),expression:"[\n            `value`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Preencha o valor.',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",attrs:{"placeholder":"Insira o valor"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Empresa ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `company_id`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `company_id`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","show-search":"","disabled":_vm.companiesList.length > 0 ? false : true},on:{"change":_vm.getCompanyBranchesOnChange}},_vm._l((_vm.companiesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.trading_name)+"\n          ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":6}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Filial ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `company_branch_id`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `company_branch_id`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","disabled":_vm.companyBranchesList.length === 0,"show-search":""}},_vm._l((_vm.companyBranchesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n            "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n          ")])}),1)],1)],1),_vm._v(" "),(_vm.$route.name === 'products-budget-edit')?_c('aCol',{attrs:{"span":8}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled'},[_vm._v(" Cliente ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            `customer_id`,
            {
              rules: [
                {
                  required: false,
                  message: 'Obrigatório',
                },
              ],
            },
          ]),expression:"[\n            `customer_id`,\n            {\n              rules: [\n                {\n                  required: false,\n                  message: 'Obrigatório',\n                },\n              ],\n            },\n          ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione ou busque ID ou Nome","optionFilterProp":"txt","disabled":_vm.customers.length === 0,"loading":_vm.customers.loading,"show-search":""},on:{"search":_vm.onSearchCustomers,"select":function($event){_vm.customers.loading = false}}},_vm._l((_vm.customers.list),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.first_name}},[(item.person_type === 'Pessoa Física')?_c('div',[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+"\n            ")]):_vm._e(),_vm._v(" "),(item.person_type === 'Pessoa Jurídica')?_c('div',[_vm._v("\n              "+_vm._s(item.id)+" -"+_vm._s(item.trading_name)+"\n            ")]):_vm._e()])}),1)],1)],1):_vm._e()],1),_vm._v(" "),_c('aRow',[_c('aCol',{attrs:{"span":24}},[_c('label',{staticClass:"label"},[_vm._v(" Descrição ")]),_vm._v(" "),_c('HayaRichTextField',{attrs:{"module":"products","subFolder":"text-editor","id":_vm.tempProduct.id,"value":_vm.tempProduct.description,"placeholder":'Digite o texto aqui',"formField":"description"},on:{"textChange":_vm.onChangeText}})],1)],1),_vm._v(" "),(_vm.tempProduct.type === 'package')?_c('PackageDefaultDataSection',{staticClass:"mt-40",attrs:{"productForm":_vm.productForm,"tempProduct":_vm.tempProduct}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("Dados básicos")])])
}]

export { render, staticRenderFns }