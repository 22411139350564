<template>
  <div>
    <div v-for="(row, i) in repeaterRows" :key="i">
      <aRow :gutter="10">
        <aCol :span="22">
          <div v-for="(field, i) in fieldsArr" :key="i">
            <aCol v-if="field.type == 'text'" :span="field.colSize">
              <aFormItem
                class="travel-input-outer"
                :class="field.formItemClass"
              >
                <aInput
                  class="travel-input"
                  :class="field.class"
                  :placeholder="field.placeholder"
                  v-decorator="[
                    `${field.name}_${row.id}`,
                    {
                      rules: [
                        {
                          required: field.required,
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </aInput>
              </aFormItem>
            </aCol>
          </div>
        </aCol>

        <aCol :span="2">
          <a-button
            v-if="repeaterRows.length == row.id"
            class="relative top5"
            type="danger"
            shape="circle"
            icon="delete"
            size="small"
            @click="removeRow(row.id)"
          />
        </aCol>
      </aRow>
    </div>

    <div class="a-center mt-20">
      <a-button @click="addRow(repeaterRows.length)" class="f12" size="small">
        Adicionar
      </a-button>

      <aFormItem class="none">
        <aInput
          v-decorator="[arrName, { initialValue: repeaterRowsStr, rules: [] }]"
        >
          <a-icon slot="prefix" type="field-svg" />
        </aInput>
      </aFormItem>
    </div>
  </div>
</template>

<script>
export default {
  name: "HayaRepeaterFields",
  props: {
    arr: String,
    form: Object,
    fieldsArr: Array,
    arrName: String,
    entry: Object,
  },
  data() {
    return {
      repeaterRows: [{ id: 1 }],
      repeaterRowsStr: "",
    };
  },
  beforeMount() {
    if (this.arr) this.repeaterRows = JSON.parse(this.arr);
  },
  mounted() {
    this.form.setFieldsValue(this.entry);
  },
  methods: {
    addRow(id) {
      let newId = id + 1;
      this.repeaterRows.push({
        id: newId,
      });

      (this.repeaterRowsStr = JSON.stringify(this.repeaterRows)),
        this.form.setFieldsValue({
          [this.arrName]: JSON.stringify(this.repeaterRows),
        });
    },
    removeRow(id) {
      for (let i in this.repeaterRows) {
        if (this.repeaterRows[i].id == id) {
          this.repeaterRows.splice(i, 1);
          break;
        }
      }

      (this.repeaterRowsStr = JSON.stringify(this.repeaterRows)),
        this.form.setFieldsValue({
          [this.arrName]: JSON.stringify(this.repeaterRows),
        });
    },
  },
};
</script>
